import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PiracyForm.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../AppContext";

function PiracyForm() {
  const navigate = useNavigate();
  const [OrderNum, setOrderNum] = useState("");
  const [OnlineSeller, setOnlineSeller] = useState("");
  const [BookShop, setBookShop] = useState("");
  const [BookshopAddress, setBookshopAddress] = useState("");
  const [purchaseOption, setPurchaseOption] = useState("yes");
  const [purchaseSource, setPurchaseSource] = useState("");
  const [OnlineMode, setOnlineMode] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const location = useLocation();
  const { email } = location.state || {};
  const { lastScannedBarcode: barcodeFromState } = location.state || {};
  console.log("Location state:", location.state);

  const { lastScannedBarcode: barcodeFromContext } = useAppContext();

  const lastScannedBarcode =
    barcodeFromState !== undefined && barcodeFromState !== null
      ? barcodeFromState
      : barcodeFromContext;

  const handleSave = async () => {
    if (
      (!BookShop || !pincode || city === "City not found") &&
      (!OrderNum || !OnlineSeller)
    ) {
      alert("Please fill in all required fields correctly.");
      return;
    }
    try {
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/updateStudentBooks/${email}`,
        {
          Books_Purchased: [
            {
              searchBarcode: lastScannedBarcode,
              pincode,
              city,
              OnlineMode,
              OrderNum,
              OnlineSeller,
              BookShop,
              BookshopAddress,
            },
          ],
        }
      );

      console.log("API Response:", response.data);

      // Add any additional logic based on the API response if needed

      // Navigate to the next page
      navigate("/declaration", {
        state: {
          email: email,
          lastScannedBarcode: lastScannedBarcode,
        },
      });
    } catch (error) {
      console.error("Error saving data:", error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Duplicate Entry"
      ) {
        // Display a user-friendly message on the frontend
        alert("You have already checked this Barcode.");
      }
    }
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.postalpincode.in/pincode/${pincode}`
        );

        if (response.data && response.data[0].Status === "Success") {
          const data = response.data[0].PostOffice[0];
          setCity(data.District);
          setState(data.State);
        } else {
          setCity("City not found");
          setState("State not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (pincode) {
      fetchData();
    } else {
      setCity("");
      setState("");
    }
  }, [pincode]);

  const handlePurchaseOptionChange = (event) => {
    setPurchaseOption(event.target.value);
    setPurchaseSource("");
  };

  const handlePurchaseSourceChange = (event) => {
    setPurchaseSource(event.target.value);
  };

  const handleSelectChange = (event) => {
    setOnlineMode(event.target.value);
  };

  return (
    <div className="Maindiv">
      {/* <h5 className="haveu">Have you purchased this book?</h5>
      <div className="yn_rad">
        <input
          type="radio"
          id="yes"
          name="purchaseOption"
          value="yes"
          checked={purchaseOption === "yes"}
          onChange={handlePurchaseOptionChange}
        />
        <label htmlFor="yes">Yes</label>
        <input
          type="radio"
          id="no"
          name="purchaseOption"
          value="no"
          checked={purchaseOption === "no"}
          onChange={handlePurchaseOptionChange}
        />
        <label htmlFor="no">No</label>
      </div> */}
      {purchaseOption === "yes" && (
        <div>
          <label>
            We need some information about you while dealing with this code.
          </label>
          <br /> <br />
          <label>
            <b>From where did you purchase this book?</b>
          </label>
          <div className="yn_rad">
            <input
              type="radio"
              id="online"
              value="online"
              checked={purchaseSource === "online"}
              onChange={handlePurchaseSourceChange}
            />
            <label htmlFor="online">Online</label>
            <input
              type="radio"
              id="localb"
              value="localb"
              checked={purchaseSource === "localb"}
              onChange={handlePurchaseSourceChange}
            />
            <label htmlFor="localb">Local_Bookshop</label>
          </div>
          {purchaseSource === "online" && (
            <>
              <div className="no-ele">
                <label>From where did you purchase?</label>
                <select
                  className="nero"
                  value={OnlineMode}
                  onChange={handleSelectChange}
                >
                  <option disabled value="">
                    Choose Online Mode
                  </option>
                  <option value="Amazon">Amazon</option>
                  <option value="FlipKart">FlipKart</option>
                  <option value="Other-Websites">Other Websites</option>
                </select>
                {/* <p>Selected Value: {selectedValue}</p> */}
              </div>
              <div className="no-ele">
                <label>
                  Order Number(check invoice):
                  <input
                    type="text"
                    className="nero"
                    value={OrderNum}
                    onChange={(e) => setOrderNum(e.target.value)}
                  />
                </label>
              </div>
              <div className="no-ele">
                <label>
                  Seller Name:
                  <input
                    type="text"
                    className="nero"
                    value={OnlineSeller}
                    onChange={(e) => setOnlineSeller(e.target.value)}
                  />
                </label>
              </div>
            </>
          )}
          {purchaseSource === "localb" && (
            <>
              <div className="no-ele">
                <label>
                  Name of Source/Bookshop you purchased this book:
                  <input
                    type="text"
                    className="nero"
                    value={BookShop}
                    onChange={(e) => setBookShop(e.target.value)}
                  />
                </label>
              </div>
              <div className="no-ele">
                <label>
                  Address of Bookshop:
                  <input
                    type="text"
                    className="nero"
                    value={BookshopAddress}
                    onChange={(e) => setBookshopAddress(e.target.value)}
                  />
                </label>
              </div>
              <div className="optno">
                <div className="no-ele">
                  <label>Enter your PinCode :</label>
                  <input
                    type="text"
                    className="nero"
                    value={pincode}
                    maxLength={6}
                    minLength={6}
                    onChange={handlePincodeChange}
                    required
                  />{" "}
                </div>
                <h4>Place : {city}</h4>
                <h4>State : {state}</h4>
              </div>
            </>
          )}
        </div>
      )}

      {purchaseOption === "no" && (
        <div className="optno">
          <div className="no-ele">
            <label>Enter your PinCode :</label>
            <input
              type="text"
              className="nero"
              value={pincode}
              maxLength={6}
              minLength={6}
              onChange={handlePincodeChange}
              required
            />{" "}
          </div>
          <h4>Place : {city}</h4>
          <h4>State : {state}</h4>
        </div>
      )}
      <button type="submit" className="summit" onClick={handleSave}>
        Save
      </button>
    </div>
  );
}

export default PiracyForm;
