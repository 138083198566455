// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-reader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: filter 0.5s ease; /* Add transition for smoother blur effect */
}

.blurred .qr-reader {
  filter: blur(
    5px
  ); /* Blur camera feed when the container has the "blurred" class */
}

.scanned-barcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  padding: 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/BarcodeScannerAndSearch.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4BAA4B,EAAE,4CAA4C;AAC5E;;AAEA;EACE;;GAEC,EAAE,gEAAgE;AACrE;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC;;;;;GAKC,EAAE,sCAAsC;EACzC,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".qr-reader {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  transition: filter 0.5s ease; /* Add transition for smoother blur effect */\n}\n\n.blurred .qr-reader {\n  filter: blur(\n    5px\n  ); /* Blur camera feed when the container has the \"blurred\" class */\n}\n\n.scanned-barcode {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: rgba(\n    255,\n    255,\n    255,\n    0.8\n  ); /* Semi-transparent white background */\n  padding: 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
