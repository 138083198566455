// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-progress-bar {
    width: 100px;
    height: 100px;;
    border-radius: 25px; /* Adjust the border-radius to control the roundness */
    overflow: hidden; /* Ensure content stays within rounded border */
  }

  .btn-ct-sent-otp-wa {
    display: block;
    margin-top: 16px;
    margin-left: 32px;
    cursor: pointer !important;
    font-size: inherit !important;
    border-radius: 37px !important;
    padding: 0px 30px !important;
    font-family: sans-serif;
    border: none !important;
    background-color: green !important;
    color: white !important;
    height: 44px;
    margin-right: inherit !important;
  }

  .wa-icon {

  }

  .btn-ct-resend-otp {
    cursor: pointer !important;
    font-size: inherit !important;
    border-radius: 37px !important;
    padding: 0px 30px !important;
    font-family: sans-serif;
    border: none !important;
    background-color: #3057F5 !important;
    color: white !important;
    margin-left: 62px;
    height: 44px;
    margin-right: inherit !important;
  }

  .span-ct-resend {
    padding-top: 5px;
    margin-left: 63px;
    font-size: 16px;
    color: #7C7C7C;
    font-weight: 400;
    font-family: sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/login/CountdownTimer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB,EAAE,sDAAsD;IAC3E,gBAAgB,EAAE,+CAA+C;EACnE;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,6BAA6B;IAC7B,8BAA8B;IAC9B,4BAA4B;IAC5B,uBAAuB;IACvB,uBAAuB;IACvB,kCAAkC;IAClC,uBAAuB;IACvB,YAAY;IACZ,gCAAgC;EAClC;;EAEA;;EAEA;;EAEA;IACE,0BAA0B;IAC1B,6BAA6B;IAC7B,8BAA8B;IAC9B,4BAA4B;IAC5B,uBAAuB;IACvB,uBAAuB;IACvB,oCAAoC;IACpC,uBAAuB;IACvB,iBAAiB;IACjB,YAAY;IACZ,gCAAgC;EAClC;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,uBAAuB;EACzB","sourcesContent":[".rounded-progress-bar {\n    width: 100px;\n    height: 100px;;\n    border-radius: 25px; /* Adjust the border-radius to control the roundness */\n    overflow: hidden; /* Ensure content stays within rounded border */\n  }\n\n  .btn-ct-sent-otp-wa {\n    display: block;\n    margin-top: 16px;\n    margin-left: 32px;\n    cursor: pointer !important;\n    font-size: inherit !important;\n    border-radius: 37px !important;\n    padding: 0px 30px !important;\n    font-family: sans-serif;\n    border: none !important;\n    background-color: green !important;\n    color: white !important;\n    height: 44px;\n    margin-right: inherit !important;\n  }\n\n  .wa-icon {\n\n  }\n\n  .btn-ct-resend-otp {\n    cursor: pointer !important;\n    font-size: inherit !important;\n    border-radius: 37px !important;\n    padding: 0px 30px !important;\n    font-family: sans-serif;\n    border: none !important;\n    background-color: #3057F5 !important;\n    color: white !important;\n    margin-left: 62px;\n    height: 44px;\n    margin-right: inherit !important;\n  }\n\n  .span-ct-resend {\n    padding-top: 5px;\n    margin-left: 63px;\n    font-size: 16px;\n    color: #7C7C7C;\n    font-weight: 400;\n    font-family: sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
