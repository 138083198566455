// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.span-login-register {
    font-family: serif;
    color: #A6A6A6;
    font-size: 20px;
    cursor: pointer;
}

.div-login {
    padding: 0 20% 0 0 !important;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/login/LoginHome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB","sourcesContent":[".span-login-register {\n    font-family: serif;\n    color: #A6A6A6;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.div-login {\n    padding: 0 20% 0 0 !important;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
