import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
export const cookie_validation_context = createContext();

export function Context({ children }) {
    const [cookieAuthentication, setCookieAuthentication] = useState(false);
    const cookie_jwt_Authentication = async (cookies_token) => {

        try {
            let data = { "cookies_token": cookies_token };
            if (data) {


                console.log("data", data)
                let url = 'https://auth.ssccglpinnacle.com/token-authentication';
                console.log(url);
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(cookies_token),
                });

                console.log('Response Status:', response.status);
                if (response.status === 200) {
                    setCookieAuthentication(true);
                }
                else {
                    // alert("Invalid cookies found!");
                    // Assuming you have a function like removeCookie
                    // removeCookie("jwtoken");
                    setCookieAuthentication(false);
                    // navigate('/');
                }
            }

        } catch (err) {
            console.log('Error fetching data:');
            console.error(err);
        }
    }

    return (
        <cookie_validation_context.Provider value={{ cookieAuthentication, setCookieAuthentication, cookie_jwt_Authentication }}>
            {children}
        </cookie_validation_context.Provider>
    );
}

// Custom hook to use the context
export function useCookieValidation() {
    const context = useContext(cookie_validation_context);
    if (!context) {
        throw new Error('useCookieValidation must be used within a ContextProvider');
    }
    return context;
}
