import { React, useState, useEffect } from "react";
import "./Declaration.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../AppContext";

function Declaration() {
  const [source, setSource] = useState("");
  const [matData, setMatData] = useState([]);
  const [registrationMessage, setRegistrationMessage] = useState(null);

  const location = useLocation();
  const { email } = location.state || {};
  const { lastScannedBarcode: barcodeFromState } = location.state || {};
  console.log(email);

  const { lastScannedBarcode: barcodeFromContext } = useAppContext();
  

  const lastScannedBarcode =
    barcodeFromState !== undefined && barcodeFromState !== null
      ? barcodeFromState
      : barcodeFromContext;

      console.log(lastScannedBarcode);
  useEffect(() => {
    axios
      .get("https://nodei.ssccglpinnacle.com/getApproveDPO")
      .then((response) => {
        const latest30Data = response.data.map(
          ({ shipmentid, distributorName }) => ({
            shipmentid,
            distributorName,
          })
        );
        setMatData(latest30Data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // console.log(matData);
  const handleSubmit = async () => {
    try {
      if (lastScannedBarcode.includes("-")) {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/searchBarcode/${lastScannedBarcode}`
        );
        const data1 = await response.data;
        if (data1 === "Not Found") {
          // Execute POST request for 'Not Found'
          const secondResponse = await axios.post(
            "https://nodei.ssccglpinnacle.com/searchBarr",
            { searchValue: lastScannedBarcode }
          );
          const data2 = await secondResponse.data;
          if (
            data2.result === "Distributor" ||
            data2.result === "Pinnacle B2C" ||
            data2.result === "Pinnacle Warehouse" ||
            data2.result === "Flipkart B2C" ||
            data2.result === "Amazon B2C"
          ) {
            if (data2.result === "Distributor") {
              const shipmentid = data2.OrderNum;
              const distributor = matData.find(
                (item) => item.shipmentid === shipmentid
              );
              if (distributor) {
                setSource(distributor.distributorName);
              }
            } else setSource(data2.result);
            setRegistrationMessage(
              `Verified - You have earned coin with respect to this book${data2.result}`
            );
            const response = await axios.put(
              `https://auth.ssccglpinnacle.com/updateStudentBooksAndCoins/${email}`,
              {
                searchBarcode: lastScannedBarcode,
              }
            );
            // Handle success response
            console.log("Updated student:", response.data);
          } else {
            setRegistrationMessage("This Book not matched with our Database");
          }
        } else if (data1 === "Already Exist") {
          setRegistrationMessage(
            "This book has already been registered by another User."
          );
        } else {
          console.log(data1);
        }
      } else {
        const response = await axios.get(
          `https://nodei.ssccglpinnacle.com/getKey/${lastScannedBarcode}`
        );
        const key = response.data.key;

        console.log(key);

        if (!key) {
          setRegistrationMessage("Key not found for given value");
          return; // Exiting the function if key is not found
        }
        const respons = await axios.get(
          `https://auth.ssccglpinnacle.com/searchBarcode/${lastScannedBarcode}`
        );
        const data1 = await respons.data;

        console.log(data1);

        if (data1 === "Not Found") {
          // Execute POST request for 'Not Found'
          const secondResponse = await axios.post(
            "https://nodei.ssccglpinnacle.com/searchBarr",
            { searchValue: key }
          );
          const data2 = await secondResponse.data;
          console.log(data2);

          if (
            data2.result === "Distributor" ||
            data2.result === "Pinnacle B2C" ||
            data2.result === "Pinnacle Warehouse" ||
            data2.result === "Flipkart B2C" ||
            data2.result === "Amazon B2C"
          ) {
            if (data2.result === "Distributor") {
              const shipmentid = data2.OrderNum;
              const distributor = matData.find(
                (item) => item.shipmentid === shipmentid
              );
              if (distributor) {
                setSource(distributor.distributorName);
              }
            } else setSource(data2.result);
            setRegistrationMessage(
              `Verified - You have earned coin with respect to this book`
            );
            const response = await axios.put(
              `https://auth.ssccglpinnacle.com/updateStudentBooksAndCoins/${email}`,
              {
                searchBarcode: lastScannedBarcode,
              }
            );

            // Handle success response
            console.log("Updated student:", response.data);
          } else {
            setRegistrationMessage("This Book not matched with our Database");
          }
        } else if (data1 === "Already Exist") {
          setRegistrationMessage(
            "This book has already been registered by another User."
          );
        } else {
          console.log(data1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="Maindiv">
      <h5>Book Registration</h5>
      <p>
        I hereby declare that I have purchased below book having unique code on
        the book.you may take any action if I provided wrong information and
        claimed the book which was not purchased by me.{" "}
      </p>
      <button type="submit" className="summit" onClick={handleSubmit}>
        Submit
      </button>
      {registrationMessage && <h3>{registrationMessage}</h3>}
      {source && <h2>Book Source : {source}</h2>}
    </div>
  );
}
export default Declaration;
