// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Maindiv {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.haveu {
  margin-bottom: 10px;
}

.yn_rad {
  display: flex;
  margin-bottom: 20px;
}

.yn_rad input {
  margin-right: 5px;
}
.yn_rad label {
  margin-right: 4rem;
  margin-top: 0.7rem;
}

.no-ele {
  margin-bottom: 15px;
}

.nero {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.optno {
  margin-top: 20px;
}

.summit {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.summit:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/component/PiracyForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".Maindiv {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.haveu {\n  margin-bottom: 10px;\n}\n\n.yn_rad {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.yn_rad input {\n  margin-right: 5px;\n}\n.yn_rad label {\n  margin-right: 4rem;\n  margin-top: 0.7rem;\n}\n\n.no-ele {\n  margin-bottom: 15px;\n}\n\n.nero {\n  width: 100%;\n  padding: 8px;\n  margin-top: 5px;\n  margin-bottom: 10px;\n  box-sizing: border-box;\n}\n\n.optno {\n  margin-top: 20px;\n}\n\n.summit {\n  background-color: #4caf50;\n  color: white;\n  padding: 10px 15px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.summit:hover {\n  background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
