// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-div {
    margin: 5%;
}

.p-div {
    background: #E1E3EA;
    border-radius: 60px;
    padding: 32px;
}

.plateform-span {
    font-family: serif;
    color: #737171;
    font-size: 36px;
    padding-left: 35px;
    font-weight: 700;
}

.content-div {
    margin-top: 25px;
    display: flex;
    align-items: center;
}

.text-span {
    font-family: serif;
    color: #737171;
    font-weight: 500;
    margin-left: 15px;
    font-size: 32px;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/login/Plateforms.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".main-div {\n    margin: 5%;\n}\n\n.p-div {\n    background: #E1E3EA;\n    border-radius: 60px;\n    padding: 32px;\n}\n\n.plateform-span {\n    font-family: serif;\n    color: #737171;\n    font-size: 36px;\n    padding-left: 35px;\n    font-weight: 700;\n}\n\n.content-div {\n    margin-top: 25px;\n    display: flex;\n    align-items: center;\n}\n\n.text-span {\n    font-family: serif;\n    color: #737171;\n    font-weight: 500;\n    margin-left: 15px;\n    font-size: 32px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
