import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginHome from "./login/LoginHome";
import Home from "./component/Home";
import PiracyBarcode from "./PiracyBarcode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Context } from "./component/Context_Component/Context";
import { AppProvider } from "./AppContext";
import PiracyForm from "./component/PiracyForm";
import Declaration from "./component/Declaration";
import SetPassword from "./login/SetPassword";
import Loggedinpage from "./login/loggedinpage";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (
      window.location.hostname === "3.6.254.104" &&
      window.location.port === "3000"
    ) {
      window.location.href = "https://piracy.ssccglpinnacle.com/";
    }
  }, []);
  return (
    <>
      <Context>
        <GoogleOAuthProvider clientId="991105793073-1dikvgcrhdiqahsm2056906unah9tpnb.apps.googleusercontent.com">
          <AppProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<PiracyBarcode />} />
                <Route exact path="/callback" element={<LoginHome />} />
                <Route exact path="/LoginHome" element={<LoginHome />} />
                <Route
                  exact
                  path="/verify-email/:token/:lastScannedBarcode"
                  component={SetPassword}
                  element={<LoginHome />}
                />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/PiracyForm" element={<PiracyForm />} />
                <Route exact path="/Declaration" element={<Declaration />} />
                <Route exact path="/loggedinpage" element={<Loggedinpage />} />
              </Routes>
            </BrowserRouter>
          </AppProvider>
        </GoogleOAuthProvider>
      </Context>
    </>
  );
}

export default App;
